import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import CEA_TAUXCONDITIONS from '../../assets/pdf/CEA_TAUXCONDITIONS_09_2024.pdf';
import CEA_TARIFS from '../../assets/pdf/CEA_TARIFS_01.01.2024.pdf';


class Footer extends Component {

    render(){
        return (
            <div>
                <footer className="col-md-12">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <h3>Contact</h3>
                                <p><span>Caisse d’Epargne d’Aubonne <br />société coopérative</span> <br />
                                Rue de l’Hôtel-de-Ville 21 <br />
                                Case postale 56 <br />
                                1170 Aubonne <br /><br />

                            <span>Horaires du lundi au vendredi</span><br /> 7h30 – 12h00 / 13h30 – 17h00 <br /><br />

                                    <span>Téléphone</span> 0041 21 821 12 60 <br />
                                    <span>Fax</span> 021 808 79 42
                                <br /><span>E-mail</span> <a href="mailto:info@ceanet.ch">info@ceanet.ch</a></p>
                            </div>
                            <div className="col-md-3">
                                <h3>Coordonnées bancaires</h3>
                                <p><span>Clearing bancaire</span> 8327 <br />
                                    <span>CCP</span> 10-538-4 <br />
                                    <span>BIC | swift</span> RBABCH22CEA</p>
                            </div>
                            <div className="col-md-3">
                                <h3>Mentions légales</h3>
                                <ul>
                                    <li><Link to="/Conditions" >Protection des données</Link></li>
                                    <li><Link to="/Conditions" >Conditions générales</Link></li>
                                    <li><Link to="/Conditions" >Garantie dépôts</Link></li>
                                    <li><Link to="/Conditions" >Conditions d’utilisation du NET-BANKING</Link></li>
                                    <li><a href={CEA_TAUXCONDITIONS} target="_blank">Taux et conditions</a></li>
                                    <li><a href={CEA_TARIFS} target="_blank">Tarifs des prestations</a></li>
                                </ul>
                            </div>
                            <div className="col-md-3 liens-rapides">
                                <h3>Liens rapides</h3>
                                <ul>
                                    <li><Link to="/" >Accueil</Link></li>
                                    <li><Link to="/LaBanque">La Banque</Link></li>
                                    <li><Link to="/Comptes" >Comptes</Link></li>
                                    <li><Link to="/Credits" >Crédits</Link></li>
                                    <li><Link to="/Services">Services</Link></li>
                                    <li><Link to="/VosProjets" >Vos Projets</Link></li>
                                    <li><Link to="/Actualites">Actualités</Link></li>
                                    <li><Link to="/AideFaq">Aide & FAQ</Link></li>
                                    <li><Link to="/Contact" >Contact</Link></li>
                                    <li><a href="https://wwwsec.ceanet.ch/authen/login?lang=frt"  rel="noopener noreferrer" target="_blank">E-banking</a></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }

}

export default Footer
